// CardFlipComponent.jsx
import React from "react";
import "./CardFlip.css";
import { howToBuy } from "../../data/constants";

const cards = [
  { front: "Front 1", back: "Back 1" },
  { front: "Front 2", back: "Back 2" },
  { front: "Front 3", back: "Back 3" },
  { front: "Front 4", back: "Back 4" },
];

const CardFlipComponent = () => {
  return (
    <div className="flex flex-wrap justify-center items-center gap-6 mt-6">
      {howToBuy.map((card, index) => (
        <div
          key={index}
          className="card"
        >
          <div className="card-inner">
            <div className="card-front flex-col items-center py-20 gap-4">
              <img
                src={`/images/Hero/${card.front.image}.png`}
                alt=""
                srcset=""
                className="w-20 h-20 rounded-full"
              />
              <div className="text-content font-bold">{card.front.title}</div>
            </div>
            <div className="card-back  flex-col items-center gap-4 py-20 ">
              <div className="text-content text-lg font-bold ">
                {card.back.title}
              </div>
              <div className="text-content text-[13px] text-center px-4">
                {card.back.desc}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardFlipComponent;
