import React, { Fragment } from "react";
import { heroIcons } from "../../data/constants";
import HeroButtons from "./HeroButtons";
import { motion } from "framer-motion";
import Container, { NewContainer } from "../Container";

export default function Hero() {
  return (
    <Fragment>
      <NewContainer id="Home">
        <div className="flex items-center min-h-screen lg:justify-between lg:flex-row flex-col pt-40  justify-center lg:gap-20 gap-5 ">
          <div className="flex flex-col gap-2 mt-[50px] lg:w-[65%] space-y-3 sm:space-y-4">
            <motion.h2
              initial={{ opacity: 0, translateX: "-50%" }}
              whileInView={{ opacity: 1, translateX: "0%" }}
              transition={{
                delay: 0.2,
                duration: 0.5,
                type: "spring",
                stiffness: 60,
              }}
              viewport={{ once: true, amount: 0.5 }}
              className="text-black font-extrabold font-Comfortaa  xs:text-[4rem] text-3xl uppercase"
            >
              $Geckofroggy
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, translateX: "-50%" }}
              whileInView={{ opacity: 1, translateX: "0%" }}
              transition={{
                delay: 0.2,
                duration: 0.5,
                type: "spring",
                stiffness: 60,
              }}
              viewport={{ once: true, amount: 0.5 }}
              className="text-black font-Comfortaa  xs:text-[25px] text-[16px] font-bold leading-relaxed"
            >
              Geckofroggy, a charismatic Gecko Frog, was the inspiration behind
              PEPE, a cryptocurrency meme. Her adorable features and personality
              captured hearts.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, translateX: "-50%" }}
              whileInView={{ opacity: 1, translateX: "0%" }}
              transition={{
                delay: 0.2,
                duration: 0.5,
                type: "spring",
                stiffness: 60,
              }}
              viewport={{ once: true, amount: 0.5 }}
              className="text-black font-Comfortaa  xs:text-[25px] text-[16px] font-bold leading-relaxed"
            >
              No Team Token.
            </motion.p>
            <div className="flex gap-2  mt-5">
              {heroIcons.map((icon, idx) => {
                return (
                  <motion.a
                    href={icon.link}
                    target="_blank"
                    key={icon.title}
                    className="w-10 h-10 rounded-full"
                  >
                    <motion.img
                      initial={{ opacity: 0, translateY: "-100%" }}
                      whileInView={{ opacity: 1, translateY: "0%" }}
                      transition={{
                        delay: idx * 0.1,
                        duration: 0.5,
                        type: "spring",
                        stiffness: 70,
                      }}
                      viewport={{ once: true, amount: 0.5 }}
                      src={`/images/Hero/${icon.image}.png`}
                      alt={icon.title}
                      loading="lazy"
                      className="cursor-pointer w-10 h-10 rounded-full"
                    />
                  </motion.a>
                );
              })}
            </div>
          </div>
          <div>
            <motion.img
              initial={{ opacity: 0, translateX: "50%" }}
              whileInView={{ opacity: 1, translateX: "0%" }}
              transition={{
                delay: 0.2,
                duration: 0.5,
                type: "spring",
                stiffness: 70,
              }}
              viewport={{ once: true, amount: 0.5 }}
              src="/images/Hero/geckof.png"
              alt="herogif"
              className="h-full w-full"
            />
          </div>

          {/* <motion.img
          initial={{ opacity: 0, translateY: "-100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.1,
            duration: 0.5,
            type: "spring",
            stiffness: 60,
          }}
          src="/images/Hero/rightpepe.png"
          alt="pepekill"
          loading="lazy"
          className="md:relative absolute right-0 md:top-[-200px] ss:top-[100px] top-[50px] lg:h-[40rem] ss:h-[30rem] h-[20rem] -z-10"
        /> */}
        </div>
      </NewContainer>
    </Fragment>
  );
}
