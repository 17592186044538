import React, { Fragment } from "react";

export default function Footer() {
  return (
    <Fragment>
      <div className=" py-2 mt-[50px] bg-navBg">
        <p className="text-center font-bold text-[13px]">
          ©{new Date().getFullYear()} by $GECKOFROGGY. All rights reserved on
          the Blockchain
        </p>
      </div>
    </Fragment>
  );
}
