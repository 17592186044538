import React, { Fragment } from "react";
import { howToBuy } from "../../data/constants";
import { motion } from "framer-motion";
import CardFlipComponent from "../Flip/CardFlip";

export default function How() {
  return (
    <Fragment>
      <div
        className=" mt-[200px] "
        id="How to buy"
      >
        <motion.h1
          className="font-inter text-black font-bold lg:w-[30%] w-[80%] mx-auto text-[45px] sm:py-[30px] text-center"
          initial={{ opacity: 0, translateY: "100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.1,
            duration: 0.5,
            type: "spring",
            stiffness: 60,
          }}
          viewport={{ once: true, amount: 0.5 }}
        >
          HOW TO BUY
        </motion.h1>
        <div className="w-[70%] mx-auto gap-5 flex flex-col ">
          <CardFlipComponent />
        </div>
      </div>
    </Fragment>
  );
}
