export const navlinks = [
  {
    tittle: "About",
    link: "",
  },
  {
    tittle: "How to buy",
    link: "",
  },
  {
    tittle: "Tokenomics",
    link: "",
  },
  // {
  //   tittle: "Roadmap",
  //   link: "",
  // },
];

export const heroIcons = [
  {
    title: "Twitter",
    image: "x",
    link: "https://x.com/Geckofrogy",
  },
  {
    title: "Telegram",
    image: "telegram",
    link: "https://t.me/geckofroggy",
  },
  // {
  //   title: "dexscreener",
  //   image: "dex",
  //   link: "https://dexscreener.com/solana/6iuF7qpRrzMCHrpd9KixXy8GVvGP56EyD3f6yQGbuxKC",
  // },
  // {
  //   tittle: "bscscan",
  //   image: "bscscan",
  //   link: "",
  // },
  // {
  //   tittle: "coinmarketcap",
  //   image: "coinmarketcap",
  //   link: "",
  // },
  // {
  //   tittle: "uniswap",
  //   image: "uniswap",
  //   link: "",
  // },
];

export const herobuttons = [
  {
    tittle: "Phantom",
    logo: "phantom",
    link: "",
  },
  {
    tittle: "gate.io",
    logo: "gateio",
    link: "",
  },
  {
    tittle: "HUOBI",
    logo: "huobi",
    link: "",
  },
  {
    tittle: "Poloniex",
    logo: "poloniex",
    link: "",
  },
  {
    tittle: "OKX Dex",
    logo: "okxdex",
    link: "",
  },
  {
    tittle: "LBANK",
    logo: "lbank",
    link: "",
  },
  {
    tittle: "BINGX",
    logo: "bingx",
    link: "",
  },

  {
    tittle: "DEXTOOLS",
    logo: "dextools",
    link: "",
  },
  {
    tittle: "coinmarketcap",
    logo: "coinmarketcap",
    link: "",
  },
];

export const howToBuy = [
  {
    front: {
      title: "Step 1",
      image: "phantom",
    },
    back: {
      title: "Download PHANTOM",
      desc: "Download and install phantom on the appstore, or, alternatively if you’re on desktop download and install the browser extension.",
    },
  },
  {
    front: {
      title: "Step 2",
      image: "solana",
    },
    back: {
      title: "Secure your $SOL",
      desc: "Get some $SOL into your phantom wallet to buy some $GFY",
    },
  },
  {
    front: {
      title: "Step 3",
      image: "gfy",
    },
    back: {
      title: "Buy some $GFY",
      desc: "Now go to Dexscreener, connect your wallet and paste the $GFY Contract Address to swap your SOL",
    },
  },
  {
    front: {
      title: "Step 4",
      image: "wallet",
    },
    back: {
      title: "Add to your wallet",
      desc: "Now you’re all set! Welcome aboard the next rocket to the moon, just import the contract address to view you’re holdings and you can track your earnings.",
    },
  },
];

export const roadmapDetails = [
  {
    tittle: "Phase 1",
    plans: [
      "-Pinksale Launch",
      "-GC/CMC",
      "-1000+ Meme Master Holders",
      "-Ofcourse we are gonna trend on Twitter-Make more memes",
      "-Overthrow Frogs and become Master of Memes",
    ],
  },
  {
    tittle: "Phase 2",
    plans: [
      "-5000+ Meme Master Holders",
      "-Reveal my Girlfriend (wink emoji)",
      "-Partnership in real",
      "-Give a call to CZ Binance and get the listing ready (CEX listing)",
    ],
  },
];

export const contactLinks = [
  { tittle: "Telegram", image: "telegram", link: "" },
  { tittle: "Twitter", image: "twitter", link: "" },
  { tittle: "Discord", image: "discord", link: "" },
];
