import React, { Fragment } from "react";
import { howToBuy } from "../../data/constants";
import { motion } from "framer-motion";

export default function How() {
  return (
    <Fragment>
      <div
        className="mt-20 md:mt-0"
        id="About"
      >
        <motion.h1
          className="font-inter text-black font-bold lg:w-[30%] w-[80%] mx-auto text-[45px] sm:py-[30px] text-center"
          initial={{ opacity: 0, translateY: "100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.1,
            duration: 0.5,
            type: "spring",
            stiffness: 60,
          }}
          viewport={{ once: true, amount: 0.5 }}
        >
          ABOUT
        </motion.h1>
        <div className="w-[95%] sm:w-[70%] mx-auto gap-5 flex flex-col">
          <div className="  bg-cardBrown flex sm:flex-row flex-col items-center gap-10 justify-center py-7 rounded-3xl rounded-tl-none rounded-br-none border-2 border-darkYellow shadow-transparent  shadow-box">
            {/* <img
              src={`/images/About/geckof.png`}
              loading="lazy"
              className="h-52"
            /> */}
            <div className="flex flex-col text-white py-6 px-2 text gap-3 w-[90%]">
              <motion.h1
                className="font-inter font-bold text-left text-[16px]  md:text-xl"
                initial={{ opacity: 0, translateY: "200%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 100,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                In the depths of the internet, a phenomenon was born.
                Geckofroggy, a charismatic female Gecko Frog, was destined to
                become the face of a revolution. Her journey began in a humble
                shelter, where a volunteer bestowed upon her the name
                Geckofroggy, inspired by her round face resembling the fruit.
              </motion.h1>
              <motion.p
                className="font-Comfortaa text-[13px]  md:text-[16px]"
                initial={{ opacity: 0, translateY: "100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 60,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                As fate would have it, Geckofroggy's adorable features and
                endearing personality captured the hearts of many. She became
                the prototype for PEPE, a cryptocurrency meme that swept the
                nation. Her image was plastered on trading cards, memes, and
                even tattoos.
              </motion.p>
              <motion.p
                className="font-Comfortaa text-[13px]  md:text-[16px]"
                initial={{ opacity: 0, translateY: "100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 60,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                As PEPE's popularity soared, Geckofroggy's name became an
                afterthought. People forgot the inspiration behind the meme, the
                real-life frog that started it all. But $Geckofroggy remained a
                beloved symbol, her face igniting passion and excitement in the
                cryptocurrency market.
              </motion.p>
              <motion.p
                className="font-Comfortaa text-[13px]  md:text-[16px]"
                initial={{ opacity: 0, translateY: "100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 60,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                In 2024, as the market began to dwindle, a group of enthusiasts
                sought to redefine the meme and revive its former glory. They
                rallied around $Geckofroggy, remembering the humble beginnings
                of the PEPE phenomenon. Together, they declared: "Pepe is on
                fire, Pepe's breed Froggy is on fire, but we won't forget the
                name that started it all - $GECKOFROGGY!"
              </motion.p>
              <motion.p
                className="font-Comfortaa text-[13px] md:text-[16px]"
                initial={{ opacity: 0, translateY: "100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 60,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                And so, Geckofroggy's legacy lived on, inspiring a new wave of
                creativity and community in the world of cryptocurrency memes.
                Her round face and charming personality continued to captivate
                hearts, reminding everyone of the power of a simple idea and a
                beloved frog's enduring impact.
              </motion.p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
