import React, { Fragment, useState } from "react";
import { motion } from "framer-motion";

export default function Tokenomics() {
  const [copySuccess, setCopySuccess] = useState("");

  const textToCopy = "EQkKscPYEgQzFaXudeazQByuAi6CazuXw3qV9Ks2ftzH";

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Address copied");
      setTimeout(() => {
        setCopySuccess("");
      }, 2000);
    } catch (err) {
      setCopySuccess("Failed to copy");
      setTimeout(() => {
        setCopySuccess("");
      }, 2000);
    }
  };
  return (
    <Fragment>
      <div
        className="mt-[200px] w-[95%] lg:w-[80%] mx-auto"
        id="Tokenomics"
      >
        <motion.h1
          className="font-inter text-black font-bold text-center text-[45px]"
          initial={{ opacity: 0, translateY: "100px", rotateY: 180 }}
          whileInView={{ opacity: 1, translateY: "0px", rotateY: 0 }}
          transition={{
            delay: 0.1,
            duration: 0.5,
            type: "spring",
            stiffness: 100,
          }}
          viewport={{ once: true, amount: 0.5 }}
        >
          TOKENOMICS
        </motion.h1>
        <div className="flex sm:flex-row flex-col sm:gap-10 gap-5 justify-center items-center">
          <motion.img
            initial={{ opacity: 0, rotate: 360, scale: 0 }}
            whileInView={{ opacity: 1, rotate: 0, scale: 1 }}
            transition={{
              delay: 0.1,
              duration: 0.5,
              type: "spring",
              stiffness: 40,
            }}
            viewport={{ once: true, amount: 0.5 }}
            src="/images/Hero/geckof.png"
            alt="notax"
            className="  object-contain mt-10"
          />
          <motion.div
            initial={{ opacity: 0, translateY: "-150px" }}
            whileInView={{ opacity: 1, translateY: "0px" }}
            transition={{
              delay: 0.1,
              duration: 0.5,
              type: "spring",
              stiffness: 100,
            }}
            viewport={{ once: true, amount: 0.5 }}
            className="flex bg-darkYellow py-5 px-4 lg:w-[500px] w-[95%]  rounded-3xl rounded-tl-none rounded-br-none border-2 border-cardBrown shadow-box"
          >
            <div className="text-black font-Comfortaa h-fit w-full">
              <p className="leading-7">
                {/* No Taxes. No Team Token. It's that simple.
                <br />
                <br /> */}
                <ul className="list-disc relative left-5 w-[80%]">
                  <li>No Taxes</li>
                  <li>No Team Token. It's that simple.</li>
                  <li>Liquidity Locked.</li>
                  <li>
                    LP Burnt on Contract Deployment and Contract renounced.
                  </li>
                  <li>Coingecko coming soon.</li>
                  <li>Coinmarketcap coming soon.</li>
                </ul>
              </p>
              <br />
              <br />
              <div className=" flex flex-col justify-center items-center  flex-wrap max-w-full">
                {/* {copySuccess && <p>{copySuccess}</p>} */}

                <p className="font-bold text-2xl leading-10">Contract</p>
                <div className="break-words break-all whitespace-normal p-2 sm:px-0">
                  Coming Soon...
                </div>
                {/* <div className="break-words break-all whitespace-normal p-2 sm:px-0">
                  EQkKscPYEgQzFaXudeazQByuAi6CazuXw3qV9Ks2ftzH
                </div> */}
                {/* <button
                  onClick={copyToClipboard}
                  className={`flex my-2 py-2 px-4 border-2 border-black rounded-3xl    hover:scale-105  cursor-pointer bg-lightYellow`}
                >
                  Copy to Clipboard
                </button> */}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </Fragment>
  );
}
