import React, { Fragment, useEffect, useState } from "react";
import { navlinks } from "../data/constants";
import { motion } from "framer-motion";
import Container, { NewContainer } from "./Container";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    // const heroHeight = document.querySelector("#HOME").offsetHeight;
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <div
        className={` w-full  py-3 ${
          scrolled || open ? "bg-navBg" : ""
        } sm:gap-20 fixed top-0 z-10 gap-5 `}
      >
        <NewContainer className="flex items-center justify-between">
          <motion.a
            href="#Home"
            className="bg-black rounded-full"
            initial={{ opacity: 0, translateY: "50%" }}
            whileInView={{ opacity: 1, translateY: "0%" }}
            transition={{
              delay: 0.1,
              duration: 0.5,
              type: "spring",
              stiffness: 100,
            }}
          >
            <motion.img
              viewport={{ once: true, amount: 0.5 }}
              src="/images/nav/geckof.png"
              alt="sk-nav"
              loading="lazy"
              className=" h-16 w-16 sm:h-20 sm:w-20 rounded-full"
            />
          </motion.a>
          <motion.div
            className="sm:flex hidden gap-5 text-black font-inter"
            initial={{ opacity: 0, translateY: "100%" }}
            whileInView={{ opacity: 1, translateY: "0%" }}
            transition={{
              delay: 0.1,
              duration: 0.5,
              type: "spring",
              stiffness: 100,
            }}
            viewport={{ once: true, amount: 0.5 }}
          >
            {navlinks.map((nav) => {
              return (
                <a
                  key={nav.tittle}
                  // className="cursor-pointer capitalize font-medium sm:text-xl lg:text-2xl hover:underline underline-offset-8 hover:text-white"

                  className={`flex py-3 px-6 border-2 border-black rounded-3xl    hover:scale-105 cursor-pointer bg-darkYellow`}
                  href={`#${nav.tittle}`}
                >
                  {nav.tittle}
                </a>
              );
            })}
          </motion.div>
          <motion.a
            // className="text-black font-inter py-2 px-5 border-2 border-black sm:text-2xl  rounded-3xl cursor-pointer hover:border-white hover:text-white"
            className={`flex py-3 px-6 border-2 border-black rounded-3xl    hover:scale-110  cursor-pointer bg-lightYellow`}
            initial={{ opacity: 0, translateY: "100%" }}
            whileInView={{ opacity: 1, translateY: "0%" }}
            transition={{
              delay: 0.3,
              duration: 0.5,
              type: "spring",
              stiffness: 100,
            }}
            viewport={{ once: true, amount: 0.5 }}
            // href="https://dexscreener.com/solana/6iuF7qpRrzMCHrpd9KixXy8GVvGP56EyD3f6yQGbuxKC"
            // target="_blank"
          >
            Buy Now
          </motion.a>

          <img
            src={`/images/nav/${open ? "cross" : "select"}.svg`}
            className="cursor-pointer  px-5 sm:hidden flex"
            alt="ham"
            onClick={() => {
              setOpen((prev) => !prev);
            }}
          />
        </NewContainer>
        {open && (
          <motion.div
            className="absolute min-w-full  min-h-full text-black font-inter bg-navBg  px-4 py-12 flex flex-col justify-center items-center gap-5 -z-10"
            // initial={{ opacity: 0, translateY: "-100%" }}
            // whileInView={{ opacity: 1, translateY: "100%" }}
            // transition={{
            //   delay: 0.1,
            //   duration: 0.1,
            //   type: "spring",
            //   stiffness: 100,
            //   damping: 14,
            // }}
            // viewport={{ once: true, amount: 0.5 }}
          >
            {navlinks.map((nav) => {
              return (
                <motion.a
                  initial={{ opacity: 0, translateY: "-100%" }}
                  whileInView={{ opacity: 1, translateY: "0%" }}
                  transition={{
                    delay: 0.1,
                    duration: 0.1,
                    type: "spring",
                    stiffness: 100,
                  }}
                  viewport={{ once: true, amount: 0.5 }}
                  key={nav.tittle}
                  // className="cursor-pointer text-center"
                  className={` py-2 px-4 border-2 w-[50%] text-center border-black rounded-3xl    hover:scale-105 cursor-pointer bg-darkYellow`}
                  // href={nav.tittle}
                  onClick={() => {
                    setOpen((prev) => !prev);
                  }}
                  href={`#${nav.tittle}`}
                >
                  {nav.tittle}
                </motion.a>
              );
            })}
          </motion.div>
        )}
      </div>
    </Fragment>
  );
}
